<template>
  <ErrorPageWrapper title="ERROR_PAGE.503.TITLE" content="ERROR_PAGE.503.CONTENT" noButton />
</template>

<script>
import ErrorPageWrapper from "@components/ErrorPage/ErrorPageWrapper.vue";

export default {
  name: "Maintenance",

  components: {
    ErrorPageWrapper,
  },
};
</script>
